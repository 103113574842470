import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckCircle,
  faCreditCard,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import Cleave from "cleave.js/react";
import Validator from "card-validator";

function CC() {
  const [orderNumberLookup, setOrderNumberLookup] = useState();
  const [orderNumber, setOrderNumber] = useState(
    new URLSearchParams(window.location.search).get("oid")
  );
  const [orderTotal, setOrderTotal] = useState("");
  const [creditCardNum, setCreditCardNum] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardTypeIcon, setCardTypeIcon] = useState(faCreditCard);
  const [cardCVV, setCardCVV] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [saveCard, setSaveCard] = useState("");
  const [errorsList, setErrorsList] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const FORM_DATA = {
    creditCardNum: false,
    cardCVV: false,
    cardHolder: false,
    expireDate: false,
    saveCard: false,
  };

  const API_URL = `https://payment-api.voiceproconnect.com`;
  // const API_URL = `http://localhost:3030`;

  React.useEffect(() => {
    async function getOrderInfo() {
      setIsLoading(true);
      const response = await fetch(API_URL + `/orderinfo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data: { orderNumber: orderNumber } }),
      });
      setIsLoading(false);
      const result = await response.json();
      if (result.success && result.data.orderTotal) {
        setOrderTotal(result.data.orderTotal);
      } else {
        setOrderNumber(false);
      }
      return result;
    }

    if (orderNumber) {
      getOrderInfo();
    }
    return () => {};
  }, [orderNumber]);

  const handleNum = (e) => {
    setCreditCardNum(e.target.rawValue);
    e.target.style.backgroundColor = "";
  };

  const handleType = (type) => {
    setCardType(type);

    if (type === "visa") {
      setCardTypeIcon(faCcVisa);
    } else if (type === "mastercard") {
      setCardTypeIcon(faCcMastercard);
    } else if (type === "discover") {
      setCardTypeIcon(faCcDiscover);
    } else if (type === "amex") {
      setCardTypeIcon(faCcAmex);
    } else if (type === "diners") {
      setCardTypeIcon(faCcDinersClub);
    } else {
      setCardTypeIcon(faCreditCard);
    }
  };

  const handleCardHolder = (e) => {
    setCardHolder(e.target.value);
    e.target.style.backgroundColor = "";
  };

  const handleOrderNumberLookup = (e) => {
    setOrderNumberLookup(e.target.value);
    e.target.style.backgroundColor = "";
  };

  const handleExpirationDate = (e) => {
    setExpireDate(e.target.rawValue);
    e.target.style.backgroundColor = "";
  };

  const handleCVV = (e) => {
    setCardCVV(e.target.value);
    e.target.style.backgroundColor = "";
  };

  const handleSaveCard = (e) => {
    setSaveCard(e.target.checked);
    e.target.style.backgroundColor = "";
  };

  const checkErrors = () => {
    if (!Validator.number(creditCardNum).isValid) {
      FORM_DATA.creditCardNum = true;
    }

    if (!Validator.cardholderName(cardHolder).isValid) {
      FORM_DATA.cardHolder = true;
      setErrorsList((prevState) => {
        return new Map(prevState).set("cardHolder", true);
      });
    }

    if (!Validator.cvv(cardCVV).isValid && !Validator.cvv(cardCVV,4).isValid) {
      FORM_DATA.cardCVV = true;
    }

    if (!Validator.expirationDate(expireDate).isValid) {
      FORM_DATA.expireDate = true;
    }

    let isErrors = false;
    Object.keys(FORM_DATA).forEach((key) => {
      if (FORM_DATA[key] === true) {
        setErrorsList((prevState) => {
          return new Map(prevState).set(key, true);
        });
        document.getElementById(key).style.backgroundColor = "#ffcccb";
        isErrors = true;
      } else {
        setErrorsList((prevState) => {
          return new Map(prevState).set(key, false);
        });
        document.getElementById(key).style.backgroundColor = "";
      }
    });

    return isErrors;
  };

  const handleOrderLookupSubmit = async (e) => {
    e.preventDefault();

    if (
      !isNaN(orderNumberLookup) &&
      !isNaN(parseInt(orderNumberLookup)) &&
      orderNumberLookup > 300000
    ) {
      setOrderNumber(orderNumberLookup);
    } else {
      document.getElementById("orderNumberLookup").style.backgroundColor =
        "#ffcccb";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkErrors() === false) {
      const result = await processData();
      if (result.success && parseInt(result.data.confirmationNumber)) {
        setConfirmation(result.data.confirmationNumber);
      } else if (result.error) {
        setErrorMessage(result.error);
      } else {
        setErrorMessage("An error occur while processing your payment");
      }
    }
  };

  const processData = async () => {
    setIsLoading(true);

    const formData = {
      cardHolder: cardHolder,
      creditCardNum: creditCardNum,
      cardType: cardType,
      expireDate: expireDate,
      cardCVV: cardCVV,
      total: orderTotal,
      orderNumber: orderNumber,
      saveCard: saveCard,
    };

    const response = await fetch(API_URL + `/checkout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data: formData }),
    });
    setIsLoading(false);
    return await response.json();
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (errorMessage) {
    return (
      <div className="container">
        <form id="form">
          <div className="input-container mt" style={{ paddingBottom: 50 }}>
            <div style={{ width: 700 }}></div>
            <div
              style={{
                display: "flex",
                paddingBottom: 20,
                color: "red",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            ></div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                color: "red",
                fontSize: 27,
              }}
            >
              <div>
                <FontAwesomeIcon icon={faTimesCircle} size="1x" />
              </div>
              <div style={{ paddingLeft: 10 }}>There was a problem</div>
            </div>
            <div
              style={{
                paddingTop: 20,
                color: "#333",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              {errorMessage}
            </div>
            <div
              style={{
                paddingTop: 10,
                color: "#333",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              To try again, please press Continue.
            </div>
          </div>
          <button onClick={{}}>{`Continue`}</button>
        </form>
      </div>
    );
  }

  if (confirmation) {
    return (
      <div className="container">
        <form id="form">
          <div className="input-container mt" style={{ paddingBottom: 50 }}>
            <div style={{ width: 700 }}></div>
            <div
              style={{
                display: "flex",
                paddingBottom: 20,
                color: "red",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            ></div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                color: "green",
                fontSize: 27,
              }}
            >
              <div>
                <FontAwesomeIcon icon={faCheckCircle} size="1x" />
              </div>
              <div style={{ paddingLeft: 10 }}>Thank you!</div>
            </div>
            <div
              style={{
                paddingTop: 20,
                color: "#333",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Your payment was submitted successfully
            </div>
            <div
              style={{
                paddingTop: 10,
                color: "#333",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Confirmation number:{" "}
              <span style={{ color: "black", fontSize: 18, fontWeight: "600" }}>
                {confirmation}
              </span>
            </div>
          </div>
          <button onClick={{}}>{`Continue`}</button>
        </form>
      </div>
    );
  }
  if (!orderNumber) {
    return (
      <div className="container">
        <form id="form">
          <div className="input-container mt">
            <div
              style={{
                display: "flex",
                paddingBottom: 20,
                color: "red",
                justifyContent: "space-between",
                alignItems: "baseline",
                minWidth: 370,
              }}
            >
              <h3
                style={{
                  color: "grey",
                  fontSize: 23,
                  paddingBottom: 20,
                  alignSelf: "center",
                }}
              >
                VoicePRO Communications
              </h3>
            </div>
          </div>
          <div className="input-container">
            <h3>
              {errorsList?.get("cardHolder") && "\u2757"}What is your
              Invoice/Order Number
            </h3>
            <input
              id="orderNumberLookup"
              onChange={handleOrderNumberLookup}
              type="text"
              placeholder="Please enter your Invoice Ref# / Order#"
              required
            />
          </div>
          <button onClick={handleOrderLookupSubmit} disabled={!!isLoading}>
            {isLoading ? `Please wait...` : `Continue`}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="container">
      <form id="form">
        <div className="input-container mt">
          <div
            style={{
              display: "flex",
              paddingBottom: 20,
              color: "red",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h3 style={{}}>
              Order Number:{" "}
              <span style={{ color: "#555", fontSize: 23 }}>{orderNumber}</span>
            </h3>
            <div style={{}}>
              <h3 style={{ color: "red" }}>
                Total: <span style={{ color: "black" }}>$</span>
                <span style={{ color: "#555", fontSize: 23 }}>
                  {orderTotal && Number.parseFloat(orderTotal).toFixed(2)}
                </span>
              </h3>
            </div>
          </div>
          <h4>
            {errorsList?.get("creditCardNum") && "\u2757"}Enter card number
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Cleave
              id="creditCardNum"
              delimiter="-"
              options={{
                creditCard: true,
                onCreditCardTypeChanged: handleType,
              }}
              required
              onChange={handleNum}
              placeholder="Please enter your credit card number"
            />
            <FontAwesomeIcon
              icon={cardTypeIcon}
              size="2x"
              style={{
                marginLeft: -50,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                zIndex: 2,
                color: "#777",
              }}
            />
          </div>
        </div>

        <div className="input-grp">
          <div className="input-container">
            <h4>{errorsList?.get("expireDate") && "\u2757"}Expiration</h4>
            <Cleave
              id="expireDate"
              placeholder="MM/YY"
              options={{ date: true, datePattern: ["m", "d"] }}
              onChange={handleExpirationDate}
            />
          </div>
          <div className="input-container">
            <h4>{errorsList?.get("cardCVV") && "\u2757"}CVV</h4>
            <input
              id="cardCVV"
              onChange={handleCVV}
              type="password"
              placeholder="CVV"
              required
            />
          </div>
        </div>

        <div className="input-container">
          <h4>{errorsList?.get("cardHolder") && "\u2757"}Cardholder Name</h4>
          <input
            id="cardHolder"
            onChange={handleCardHolder}
            type="text"
            placeholder="Please enter your full name"
            required
          />
        </div>

        <div className="input-container">
          <label className={"checkbox-label"} placeholder={"Remember my payment details for automatic payment of recurring charges"}>
            <input
              className={"checkbox-input"}
              type={"checkbox"}
              id={"saveCard"}
              onChange={handleSaveCard}
            />
            Remember my payment details for automatic recurring charges
          </label>
        </div>
        <div className="small">
          By checking this box, you agree to our privacy policy and terms of use.
        </div>

        <button onClick={handleSubmit} disabled={!!isLoading}>
          {isLoading ? `Please wait...` : `Submit payment`}
        </button>
      </form>
    </div>
  );
}

export default CC;
